import React from "react";
import "animate.css";
import Marquee from "react-fast-marquee";
const SecondaryButtons = () => {
  return (
    <div className="secondary-button animate__animated animate__fadeInUp animate__delay-2s">
      <button>
        <Marquee>
          <p className="marquee-text"> Download on App Store </p>
        </Marquee>
      </button>
      <button>
        <Marquee>
          <p className="marquee-text"> Download on Play Store </p>
        </Marquee>
      </button>
    </div>
  );
};

export default SecondaryButtons;
