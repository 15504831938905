import React from "react";
import "./buttons.css";
import Marquee from "react-fast-marquee";
const PrimaryButtons = () => {
  return (
    <div className="primary-buttons">
      <button>
        <Marquee direction="right">
          <p className="marquee-text">Download on App Store</p>
        </Marquee>
      </button>
      <button>
        <Marquee direction="right">
          <p className="marquee-text">Download on Play Store</p>
        </Marquee>
      </button>
    </div>
  );
};

export default PrimaryButtons;
