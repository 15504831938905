import React from "react";
import "./HeroPageDetails.css";
import "animate.css";
import SecondaryButtons from "../utils/SecondaryButtons";
const HeroPageDetails = () => {
  return (
    <div className="hero-page-details-container">
      <div className="hero-page-details-text">
        <h5 className="animate__animated animate__fadeInUp animate__fast">
          An online DAOs & Communities platform built on{" "}
          <span>Solana Network</span>
        </h5>
        <div className="hero-page-text-container">
          <div>
            <h3 className="slide-up">
              Finding your tribe online is now super easy. Beyond swiping just
            </h3>
          </div>
          <div>
            <h3 className="slide-up">
              for dates, we're here to help you find your{" "}
              <span> workout partner </span>,
            </h3>
          </div>
          <div>
            <h3 className="slide-up">
              <span>paint-and-sip partner</span>, <span>web3 buddy</span> or
              that <span> companion </span>
            </h3>
          </div>
          <div>
            <h3 className="slide-up">
              who's also dying to see the latest blockbuster.
            </h3>
          </div>
        </div>
        {/* <h3 className="animate__animated animate__fadeInUp animate__slow">
          Finding your tribe online is now super easy. Beyond swiping just for
          dates, we're here to help you find your <span> workout partner </span>
          , <span>paint-and-sip partner</span>, <span>web3 buddy</span> or that{" "}
          <span> companion </span> who's also dying to see the latest
          blockbuster.
        </h3> */}
        <SecondaryButtons />
      </div>
    </div>
  );
};

export default HeroPageDetails;
