import React, { useEffect } from "react";
import "./CTA.css";
import SecondaryButtons from "../utils/SecondaryButtons";
import ctaIllustration from "../../images/Group 7.png";
import ctaIllustrationMobile from "../../images/mobile-mockup-cta.png";
import connectionLoader from "../../images/CONEKT-loader-gif 1.gif";
import AOS from "aos";
import "aos/dist/aos.css";
const CTA = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="cta-wrapper">
      <div className="cta-container">
        <div className="cta-details">
          <div className="cta-text" data-aos="fade-up" data-aos-duration="3000">
            <h5>
              <span>Co-nekt</span> isn't just another app on your phone; it's
              your ticket to a world where finding your kind of people is the
              norm.
            </h5>
            <SecondaryButtons />
          </div>
          <div className="cta-illustration">
            <img
              src={ctaIllustration}
              alt="CTA Illustration"
              className="cta-illustration-image"
            />
            <img
              src={ctaIllustrationMobile}
              alt="CTA Illustration"
              className="cta-illustration-image-mobile"
            />
          </div>
        </div>
        <div className="connection-loader">
          <img src={connectionLoader} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CTA;
