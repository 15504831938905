import React from "react";
import "./Footer.css";
import footerMockUPGif from "../../images/footer-grouped.png";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillApple } from "react-icons/ai";
import { BsGooglePlay } from "react-icons/bs";
import Marquee from "react-fast-marquee";
const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-details">
        <div className="footer-text">
          <div className="footer-main-text">
            <h5>Making the World Smaller,</h5>
            <h5>One Connection at a Time</h5>
          </div>
          <div className="footer-buttons">
            <a href="##">
              <Marquee direction="right">
                <AiFillApple className="footer-icon" /> Download
              </Marquee>
            </a>
            <a href="##">
              <Marquee direction="right">
                <BsGooglePlay className="footer-icon" /> Download
              </Marquee>
            </a>
          </div>
          <div className="footer-socials">
            <div className="footer-socials-icon">
              <h5>Follow us on social media</h5>
              <a
                href="https://twitter.com/co_nekt_"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter className="footer-twitter-icon" />
              </a>
            </div>
            <a href="mailto:info@conektapp.io">info@conektapp.io</a>
          </div>
        </div>
        <div className="footer-illustration">
          <img src={footerMockUPGif} alt="Footer Mock Up Gif" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
