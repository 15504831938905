import { useEffect } from "react";
import React from "react";
import connectionIllustration from "../../images/connect 1.png";
import solanaImage from "../../images/solana 3.png";
import "../SearchConnect/SearchConnect.css";
import PrimaryButtons from "../utils/PrimaryButtons";
import AOS from "aos";
import "aos/dist/aos.css";
const Connections = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="connections-wrapper">
      <div className="search-connection-details">
        <div
          className="connection-text"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h5>Making the world smaller, one connection at a time</h5>
          <h3>
            Matching you with your next fitness partner or finding someone keen
            on exploring new cafes, Co-nekt's got you. While we're not in the
            business of <span>love</span> or <span>LinkedIn-style</span>{" "}
            networking, we're all about sparking friendships that matter.
          </h3>
          <PrimaryButtons />
        </div>
        <div
          className="search-connection-details-illustration"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <img
            src={connectionIllustration}
            alt="Search Connection Illustration"
          />
        </div>
      </div>
      <div className="connection bottom-image">
        <img src={solanaImage} alt="Solana Illustration" />
      </div>
    </div>
  );
};

export default Connections;
