import React from "react";
import "./HeroPage.css";
import "animate.css";
import { AiFillApple } from "react-icons/ai";
import { BsGooglePlay } from "react-icons/bs";
import conektLogo from "../../images/main-logo.svg";
import heropageImage1 from "../../images/HERO IMAGE.png";
import heropageGif from "../../images/Conekt-token-bounce 1.gif";
import HeroPageDetails from "../HeroPageDetails/HeroPageDetails";
import Marquee from "react-fast-marquee";
const HeroPage = () => {
  return (
    <div className="hero-page-container">
      <div className="nav-details">
        <div className="nav-logo">
          <a href="##">
            <img src={conektLogo} alt="" />
          </a>
        </div>
        <div className="nav-mobile-button">
          <div>
            <AiFillApple className="mobile-icons" />
          </div>
          <div>
            <BsGooglePlay className="mobile-icons" />
          </div>
        </div>
        <div className="nav-buttons">
          <Marquee direction="right">
            <a href="##" className="nav-main-button">
              <AiFillApple className="nav-icon" /> Download
            </a>
          </Marquee>
          <hr />
          <Marquee direction="right">
            <a href="##" className="nav-main-button">
              <BsGooglePlay className="nav-icon" /> Download
            </a>
          </Marquee>
        </div>
      </div>
      <HeroPageDetails />
      <div>
        <img
          src={heropageImage1}
          alt="First Hero Page"
          className="hero-page-image-1 animate__animated animate__fadeInUp animate__delay-3s"
        />
      </div>
      <div>
        <img
          src={heropageGif}
          alt="First Hero Page"
          className="hero-page-image-2 animate__animated animate__fadeInUp animate__delay-3s"
        />
      </div>
    </div>
  );
};

export default HeroPage;
