import React, { useEffect } from "react";
import "./SearchConnect.css";
import searchConnectIllustration from "../../images/glass wallpa3 2.png";
import solanaImage from "../../images/solana 2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import PrimaryButtons from "../utils/PrimaryButtons";
import Connections from "../Connections/Connections";
const SearchConnect = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="connections-wrappers">
      <div className="search-connection-wrapper">
        <div className="search-connection-details">
          <div
            className="search-connection-details-illustration"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <img
              src={searchConnectIllustration}
              alt="Search Connection Illustration"
            />
          </div>
          <div
            className="search-connection-text"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h5>Making the world smaller, one connection at a time</h5>
            <h3>
              <span>Co-nekt</span> is where you meet people who get you, share
              your passions, and are on the lookout for someone just like you to
              share their adventures.
            </h3>
            <PrimaryButtons />
          </div>
        </div>
        <div className="bottom-image">
          <img src={solanaImage} alt="Solana Illustration" />
        </div>
      </div>
      <Connections />
    </div>
  );
};

export default SearchConnect;
