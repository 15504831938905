import "./App.css";
import CTA from "./components/CTA/CTA";
import Connections from "./components/Connections/Connections";
import Footer from "./components/Footer/Footer";
import HeroPage from "./components/Heropage/HeroPage";
import SearchConnect from "./components/SearchConnect/SearchConnect";

function App() {
  return (
    <>
      <HeroPage />
      <SearchConnect />  <CTA />
      <Footer />
    </>
  );
}

export default App;
